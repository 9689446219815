
import React from 'react'
import styled from "@emotion/styled"

import SEO from "../components/seo";
import LayoutSub from "../components/layoutSub";

// Style 定義
const DTQ = styled.dt`
&::before{
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%221.5em%22%20height%3D%221.5em%22%20version%3D%221.1%22%20viewBox%3D%220%200%2070%2070%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20transform%3D%22translate(-2.2679%20-5.2917)%22%3E%3Ccircle%20cx%3D%2237.268%22%20cy%3D%2240.292%22%20r%3D%2235%22%20fill%3D%22%235f6e7e%22%20fill-rule%3D%22evenodd%22%2F%3E%3Cg%20stroke%3D%22%23000%22%20stroke-width%3D%22.26458%22%20aria-label%3D%22Q%22%3E%3Cpath%20d%3D%22m54.544%2063.096q-3.2742.84336-7.4166.84336-5.1594%200-8.1111-2.5301-2.9518-2.5301-3.1998-7.1189-8.384-.09922-13.171-5.2834-4.7873-5.1842-4.7873-14.139%200-9.1033%204.8369-14.288%204.8617-5.209%2013.444-5.209%208.5576%200%2013.419%205.209%204.8865%205.209%204.8865%2014.288%200%206.7717-2.8277%2011.485-2.8277%204.7129-8.1359%206.7221.14883%202.8525%201.4635%204.0184%201.3395%201.1658%204.316%201.1658%202.4805%200%204.6633-1.0418h.62012zm-18.38-41.821q-4.44%200-7.0693%203.5471-2.6293%203.5471-2.6293%2010.046%200%206.474%202.6293%2010.021%202.6293%203.5223%207.0693%203.5223%204.4152%200%207.0445-3.5471%202.6293-3.5719%202.6293-10.021%200-6.474-2.6293-10.021-2.6045-3.5471-7.0445-3.5471z%22%20fill%3D%22%23fff%22%20stroke%3D%22%23000%22%20stroke-width%3D%22.26458%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat:no-repeat;
  display:inline-block;
  width:1.7em;
  height:1.7em;
  content:"";
  background-size:contain;
  position:absolute;
}
`;

const DDA = styled.dd`
&::before{
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%221.5em%22%20height%3D%221.5em%22%20version%3D%221.1%22%20viewBox%3D%220%200%2070%2070%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20transform%3D%22translate(-2.2679%20-5.2917)%22%3E%3Ccircle%20cx%3D%2237.268%22%20cy%3D%2240.292%22%20r%3D%2235%22%20fill%3D%22%23f60%22%20fill-rule%3D%22evenodd%22%2F%3E%3Cg%20aria-label%3D%22A%22%3E%3Cpath%20d%3D%22m55.9%2055.109h-8.6568l-2.8277-8.1111h-14.709l-2.8277%208.1111h-8.2848l14.114-37.381h9.0785zm-13.543-13.915-5.2834-15.081-5.2834%2015.081z%22%20fill%3D%22%23fff%22%20stroke-width%3D%22.26458%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat:no-repeat;
  display:inline-block;
  width:1.7em;
  height:1.7em;
  content:"";
  background-size:contain;
  position:absolute;
  margin-top:2px;
}
`;



export default function Faq({ pageContext, location }) {

  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle = "よくあるご質問";
  const description = "西荻窪の整体院・ぺんぎん堂：よくあるご質問をまとめてあります。肩こり・首痛・頭痛は、当院のオステオパシーで根本原因を解消してすっきりしましょう！";

  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";

        return (
      <div>

        <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop}>
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
          >
            <script type="application/ld+json">
              {`
        {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "施術は痛くないですか？",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "大丈夫です。当院では無理やり捻じったりするようなことはありません。<br />心地よくて眠ってしまう方も多いです。"
    }
  }, {
    "@type": "Question",
    "name": "痛くて仰向けになれません。施術を受けることはできますか？",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "横向きやうつ伏せで施術できます。あなたの痛くない姿勢を探して施術しますのでご安心ください。"
    }
  }, {
    "@type": "Question",
    "name": "ボキボキならしますか？",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "背骨をボキボキならす矯正は一切おこなっておりません。背骨の周りの筋肉を緩めたり、心地よく揺らしたりして、背骨の歪みを矯正します。<br />ソフトで安全な整体です。"
    }
  }, {
    "@type": "Question",
    "name": "どのくらい時間がかかりますか？",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "初めてのかたは、どんな事でお困りなのか？などを伺ったりしますので、およそ90分程度かかります。2回目以降は60分程度です。<br />その日のお身体の状態によって、施術時間は多少前後いたします。"
    }
  }, {
    "@type": "Question",
    "name": "特に症状はないのですが、みてもらえますか？",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"はい、大丈夫です。今、特に症状がなくても、背骨や骨盤がゆがんでいたりすることは多いです。気持ちいい整体を体験してみたい！という方も大歓迎です。"
    }
  }, {
    "@type": "Question",
    "name": "一回で良くなりますか？",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"    状態にもよりますが、一回目の施術で良い変化はでます。長期間の身体の不調の場合は、身体の状態は戻ってしまいますので、身体のクセがぬけるまでは４～５回程度は間隔を開けないほうが効果的です。<p>初回の施術後に、お身体の状態を考慮して、理想的な通院回数と通院間隔をお伝えしております。ご提案はいたしますが、次回予約の強要や勧誘は一切ありませんのでご安心ください。</p>"
    }
  }, {
    "@type": "Question",
    "name": "どんな服装で行けばいいですか？",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"なるべく柔らかい動きやすい服装でお越しください。着替えを用意しておりますので、ご利用いただくこともできます。もちろん、お着替えをお持ちいただいてもかまいません。"}
    }
    ]
}
`}
            </script>

          </SEO>
          <h1 className="heading-b">よくあるご質問</h1>
          <dl>

            <DTQ className="mb-5"><p className="pl-10">施術は痛くないですか？</p></DTQ>
            <DDA  className="mb-8"><p className="pl-10"> 大丈夫です。当院では無理やり捻じったりするようなことはありません。心地よくて眠ってしまう方も多いです。</p></DDA>

            <DTQ className="mb-5"><p className="pl-10">痛くて仰向けになれません</p></DTQ>
            <DDA className="mb-8"><p className="pl-10">うつ伏せや横向きで施術できます。あなたの痛くない姿勢を探して施術しますのでご安心ください。</p></DDA>


            <DTQ className="mb-5"><p className="pl-10">ボキボキならしますか？</p></DTQ>
            <DDA className="mb-8"><p className="pl-10">背骨をボキボキならす矯正は一切おこなっておりません。背骨の周りの筋肉を緩めたり、心地よく揺らしたりして、背骨の歪みを矯正します。ソフトで安全な整体です。</p></DDA>


            <DTQ className="mb-5"><p className="pl-10">保険はききますか？</p></DTQ>
            <DDA className="mb-8"><p className="pl-10">保険診療はしておりません。保険診療では、保険の範囲内という制約が出てしまいますので、施術する時間や部位が限られてしまいます。そのような制約がありませんので、画一的でないオーダーメイドの施術ができます。</p></DDA>


            <DTQ className="mb-5"><p className="pl-10">どのくらい時間がかかりますか？</p></DTQ>
            <DDA className="mb-8"><p className="pl-10">初めてのかたは、どんな事でお困りなのか？などを伺ったりしますので、およそ90分程度かかります。2回目以降は60分程度です。<br />その日のお身体の状態によって、施術時間は多少前後いたします。</p></DDA>


            <DTQ className="mb-5"><p className="pl-10">特に症状はないのですが、みてもらえますか？</p></DTQ>
            <DDA className="mb-8"><p className="pl-10">はい、大丈夫です。今、特に症状がなくても、背骨や骨盤がゆがんでいたりすることは多いです。気持ちいい整体を体験してみたい！という方も大歓迎です。</p></DDA>


            <DTQ className="mb-5"><p className="pl-10">一回で良くなりますか？</p></DTQ>
            <DDA className="mb-8"><p className="pl-10">状態にもよりますが、一回目の施術で良い変化はでます。長期間の身体の不調の場合は、身体の状態は戻ってしまいますので、身体のクセがぬけるまでは４～５回程度は間隔を開けないほうが効果的です。<br />
              初回の施術後に、お身体の状態を考慮して、理想的な通院回数と通院間隔をお伝えしております。ご提案はいたしますが、次回予約の強要や勧誘は一切ありませんのでご安心ください。</p></DDA>


            <DTQ className="mb-5"><p className="pl-10">どんな服装で行けばいいですか？</p></DTQ>
            <DDA className="mb-8"><p className="pl-10">なるべく柔らかい動きやすい服装でお越しください。着替えを用意しておりますので、ご利用いただくこともできます。もちろん、お着替えをお持ちいただいてもかまいません。</p></DDA>


          </dl>

        </LayoutSub>
      </div>

      
    )
}